.homepage {
	background-color: white;
	height: 100%;
	width: 100%;
	min-height: 100vh;
	min-width: 100vw;
	position: relative;
	overflow: hidden;
}

.homepage * {
	z-index: 9;
	position: relative;
}

.homepage > div {
	background-color: white;
}

.homepage .section_2 {
	border-top: 1px solid var(--Grey-1, #8399a3);
	width: 100vw;
	background: var(--S-4, linear-gradient(180deg, #152a31 0%, #000 102.48%));
	position: relative;
	z-index: 9;
	overflow: hidden;
	padding: 100px 70px 20px;
}

.homepage .section_2 .title {
	color: #fff;
	font-family: Monument Extended;
	font-size: 26px;
	padding-right: 40px;
	width: fit-content;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	text-transform: uppercase;
	border-bottom: 1px solid #fff;
}

.homepage .section_3_sibling {
	background-color: #000;
	margin: 0;
	padding: 4rem 0;
}

.homepage .section_3_sibling .brands_card {
	background-color: white;
	margin: 0 auto;
}

.homepage .section_3_sibling .client_heading {
	text-transform: uppercase;
	font-family: Monument Extended;
	font-size: 2rem;
	font-weight: 400;
	background: var(
		--S-1,
		linear-gradient(46deg, #0092bd 0.24%, #00c67a 97.74%)
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin: 0;
	margin-left: 5%;
	opacity: 0;
	transform: translateX(-50px);
	transition: opacity 2s, transform 2s;
	margin-bottom: 2rem;
}

.animate_homepage {
	opacity: 1 !important;
	transform: translateX(0) !important;
}

.homepage .section_2 .desc {
	color: #fff;
	font-family: Montserrat;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	padding-top: 10px;
}

.homepage .section_2 svg {
	height: 500px;
	width: 500px;
}

.homepage .section_2 .section_2_content {
	display: flex;
	gap: 30px;
	align-items: center;
	justify-content: space-between;
	margin-top: -50px;
}

.homepage .section_2 .section_2_content .section_2_content_wheel {
	display: flex;
	gap: 30px;
	align-items: center;
}

.homepage .section_2 .section_2_content .section_2_content_text {
	padding-left: 100px;
}

.homepage
	.section_2
	.section_2_content
	.section_2_content_text
	.section_2_content_text_head {
	color: var(--White, #fff);
	font-family: Montserrat;
	font-size: 25px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

.homepage
	.section_2
	.section_2_content
	.section_2_content_text
	.section_2_content_text_body {
	color: var(--White, #fff);
	font-family: Montserrat;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
}

.homepage .section_2 .section_2_content .show_icon {
	min-height: 70px;
	max-height: 70px;
	min-width: 45px;
	max-width: 45px;
}

.homepage .section_3 {
	padding: 100px 70px;
}

.homepage .section_3 .title {
	color: #000;
	font-family: Monument Extended;
	font-size: 26px;
	padding-right: 40px;
	width: fit-content;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	text-transform: uppercase;
	border-bottom: 1px solid #333;
}

.homepage .section_3 .desc {
	color: #000;
	font-family: Montserrat;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	width: 25vw;
}

.homepage .section_3 .case_studies {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-row-gap: 80px;
	grid-column-gap: 5vw;
	justify-content: space-between;
	padding-top: 60px;
}

.homepage .section_4 {
	padding: 100px 70px;
}

.homepage .section_4 .title {
	color: #000;
	font-family: Monument Extended;
	font-size: 26px;
	padding-right: 40px;
	width: fit-content;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	text-transform: uppercase;
	border-bottom: 1px solid #333;
}

.homepage .section_4 .desc {
	color: #000;
	font-family: Montserrat;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	width: 25vw;
}

.homepage .section_4 .what_we_offers {
	padding-top: 150px;
	display: flex;
	flex-direction: column;
	gap: 100px;
}

.homepage .section_see_us_action {
	padding: 100px 70px;
}

.homepage .section_see_us_action .title {
	color: #000;
	font-family: Monument Extended;
	font-size: 26px;
	padding-right: 40px;
	width: fit-content;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	text-transform: uppercase;
	border-bottom: 1px solid #333;
}

.homepage .section_see_us_action video {
	background-color: gray;
	width: 100%;
	height: auto;
	border-radius: 20px;
}

.homepage .section_see_us_action .video_container {
	position: relative;
	margin-top: 50px;
}

.homepage .section_see_us_action .play_btn {
	position: absolute;
	text-align: center;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.homepage .section_5 {
	padding: 100px 70px;
}

.homepage .section_5 .title {
	color: #000;
	font-family: Monument Extended;
	font-size: 26px;
	padding-right: 40px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	text-transform: uppercase;
	border-bottom: 1px solid #333;
	width: fit-content;
}

.homepage .section_5 .desc {
	color: #000;
	font-family: Montserrat;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	width: 35vw;
}

.homepage .section_5 .associate_swiper_cards {
	padding-top: 50px;
}

.homepage .section_5 .associate_swiper_card {
	display: flex;
	min-height: 250px;
	width: 60vw;
	border-radius: 20px;
	overflow: hidden;
	transition: 1s !important;
}

.homepage .section_5 .associate_swiper_card .swiper_card_img {
	min-height: 250px;
	width: 250px;
	opacity: 1;
	transition: 1.5s;
}

.homepage .section_5 .associate_swiper_card .swiper_card_img img {
	min-height: 100%;
	width: 250px;
}

.homepage .section_5 .associate_swiper_card.swiper-slide-next .swiper_card_img {
	opacity: 0;
}

.homepage .section_5 .associate_swiper_card .swiper_card_div {
	background: linear-gradient(46deg, #0092bd 0.24%, #00c67a 97.74%);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 30px 60px 60px 30px;
	gap: 50px;
}

.homepage .section_5 .associate_swiper_card .swiper_card_div p {
	margin: 0;
}

.homepage
	.section_5
	.associate_swiper_card
	.swiper_card_div
	.swiper_card_title {
	color: var(--White, #fff);
	font-family: Montserrat;
	font-size: 19px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
}

.homepage .section_5 .associate_swiper_card .swiper_card_div .swiper_card_desc {
	color: var(--White, #fff);
	font-family: Montserrat;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
}

.homepage .section_5 .associate_swiper_cards_nav {
	display: flex;
	gap: 30px;
	padding-left: 200px;
	padding-top: 40px;
	justify-content: center;
}

.homepage .section_5 .associate_swiper_cards_nav div {
	border-radius: 100%;
	background: var(
		--S-1,
		linear-gradient(46deg, #0092bd 0.24%, #00c67a 97.74%)
	);
	box-shadow: -4px 4px 16px 0px rgba(0, 0, 0, 0.1);
	outline: 0;
	border: 0;
	height: 45px;
	width: 45px;
	cursor: pointer;
	display: flex;
}

.homepage .section_5 .associate_swiper_cards_nav div svg {
	margin: auto;
}

.homepage
	.section_5
	.associate_swiper_cards_nav
	.associate_swiper_cards_nav_prev
	svg {
	transform: rotate(180deg);
}

.homepage .section_6 {
	padding: 100px 0;
}

.homepage .section_6 .title {
	color: #000;
	font-family: Monument Extended;
	font-size: 26px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	text-transform: uppercase;
	border-bottom: 1px solid #333;
	margin-left: 70px;
	padding-right: 40px;
	width: fit-content;
}

.homepage .section_6 .founders_div {
	display: flex;
	padding: 0 20vw;
	background-color: #c2d0d6;
	border-radius: 70px 70px 0px 0px;
	margin-top: 60px;
	position: relative;
	min-height: 335px;
}

.homepage .section_6 .founders_div .founders_div_desc_div {
	padding-top: 40px;
	width: 35vw;
}

.homepage
	.section_6
	.founders_div
	.founders_div_desc_div
	.founders_div_title_div {
	display: flex;
	justify-content: space-between;
}

.homepage
	.section_6
	.founders_div
	.founders_div_desc_div
	.founders_div_title_div
	.founders_div_title {
	color: var(--black, #000);
	text-align: justify;
	font-family: Montserrat;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
}

.homepage
	.section_6
	.founders_div
	.founders_div_desc_div
	.founders_div_title_div
	.founders_div_title_linkedin {
	cursor: pointer;
}

.homepage
	.section_6
	.founders_div
	.founders_div_desc_div
	.founders_div_title_div
	.founders_div_title_linkedin
	svg {
	width: 22px;
	height: 22px;
}

.homepage .section_6 .founders_div .founders_div_desc_div .founders_div_desc {
	color: var(--black, #000);
	text-align: justify;
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	padding-top: 15px;
}

.homepage .section_6 .founders_div .founders_div_img {
	position: absolute;
	top: -60px;
	right: 20vw;
	height: 340px;
}

.homepage .section_6 .founders_div .founders_div_img img {
	height: 100%;
}

.homepage .section_6 .founders_div.left {
	margin-top: -60px;
	background: var(--S-4, linear-gradient(180deg, #152a31 0%, #000 102.48%));
	box-shadow: 0px -31px 38.1px 0px rgba(0, 0, 0, 0.1);
	min-height: 278px;
}

.homepage .section_6 .founders_div.left .founders_div_img {
	left: 20vw;
}

.homepage .section_6 .founders_div.left .founders_div_desc_div {
	margin: 0 0 0 auto;
	z-index: 2;
}

.homepage .section_6 .founders_div.left .founders_div_title {
	color: white !important;
}

.homepage .section_6 .founders_div.left .founders_div_desc {
	color: white !important;
}

.homepage .section_6 .meet_the_team {
	width: fit-content;
	border-radius: 100px;
	border: 1px solid var(--Dark-primary, #152a31);
	background: var(--White, #fff);
	box-shadow: -4px 4px 16px 0px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	padding: 10px 20px;
	margin: 40px auto auto;
	gap: 15px;
	transition: 0.5s;
	cursor: pointer;
}

.homepage .section_6 .meet_the_team:hover {
	border: 1px solid var(--Dark-primary, #152a31);
	background: #152a31;
	color: white;
}

.homepage .section_6 .meet_the_team:hover span {
	color: white;
}

.homepage .section_6 .meet_the_team:hover svg path {
	fill: white;
}

.homepage .section_6 .meet_the_team svg {
	width: 18px;
	height: 18px;
}

.homepage .section_6 .meet_the_team span {
	color: #000;
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
}

.homepage .section_7 {
	padding: 50px 70px;
}

.homepage .section_7 .title {
	color: #000;
	font-family: Monument Extended;
	font-size: 26px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	text-transform: uppercase;
	border-bottom: 1px solid #333;
	padding-right: 40px;
	width: fit-content;
}

.homepage .section_7 .featured_article_swiper {
	padding-top: 70px;
}

.homepage .section_7 .featured_article_swiper * {
	text-decoration: none;
}

.homepage .section_7 .featured_article_btn_div {
	display: flex;
	margin: 40px auto auto;
	gap: 40px;
	width: fit-content;
}

.homepage .section_7 .featured_article_btn_div .featured_article_btn {
	width: fit-content;
	border-radius: 100px;
	border: 1px solid var(--Dark-primary, #152a31);
	background: var(--White, #fff);
	box-shadow: -4px 4px 16px 0px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	padding: 10px 20px;
	gap: 15px;
	cursor: pointer;
	transition: 0.5s;
}

.homepage .section_7 .featured_article_btn_div .featured_article_btn:hover {
	background: #152a31;
}

.homepage .section_7 .featured_article_btn_div .featured_article_btn svg {
	width: 18px;
	height: 18px;
	fill: #000;
}

.homepage
	.section_7
	.featured_article_btn_div
	.featured_article_btn:hover
	svg
	path {
	fill: white;
}

.homepage .section_7 .featured_article_btn_div .featured_article_btn span {
	color: #000;
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
}

.homepage
	.section_7
	.featured_article_btn_div
	.featured_article_btn:hover
	span {
	color: white;
}

.homepage .section_7 .featured_article_btn_div .featured_article_btn:disabled {
	border: 1px solid var(--Grey-1, #8399a3);
	box-shadow: none;
	cursor: not-allowed;
}

.homepage
	.section_7
	.featured_article_btn_div
	.featured_article_btn:disabled
	svg {
	fill: #8399a3;
}

.homepage
	.section_7
	.featured_article_btn_div
	.featured_article_btn:disabled
	span {
	color: #8399a3;
}

.homepage .section1_mobile {
	display: none;
}

.homepage .section_1_mobile_footer {
	display: none;
}

.section_8 .section_8_video {
	width: 100%;
	height: auto;
}

.homepage .section_4 {
	position: relative;
	overflow-x: hidden;
}

.background_shapes {
	position: absolute;
	width: 800px;
	height: 800px;
}

.homepage .section_4 .wave1 {
	top: -20vw;
	right: -30vw;
}

.homepage .section_4 .wave2 {
	top: 10vw;
	left: -20vw;
}

.homepage .section_5 .bg_tri {
	width: 450px;
	height: 450px;
	left: -1vw;
}

.featured_article_swiper .swiper-slide {
	transition: 0.5s;
	height: 400px;
}

.featured_article_swiper .swiper-slide:hover {
	scale: 1.025;
}

@media (min-width: 768px) {
	.homepage .section_see_us_action .play_btn {
		width: 100px;
		height: 100px;
	}
}

@media (max-width: 768px) {
	.homepage .section_3_sibling .brands_card {
		display: flex;
	}

	.homepage .section_2 .section_2_content .show_icon {
		rotate: -90deg;
	}

	.homepage .section_2 .section_2_content .section_2_content_wheel {
		flex-direction: column-reverse;
	}

	.background_shapes {
		display: none;
	}

	.homepage .section1_mobile {
		display: block;
		height: max-content;
		width: 100%;
		height: 66rem;
		background-color: white;
	}

	.homepage .section1_mobile .title1 {
		color: var(--black, #000);
		text-shadow: 0px 0px 105px #fff;
		font-family: "Monument Extended";
		font-size: 1.5rem;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		text-transform: uppercase;
		margin: 0;
		margin-left: 2rem;
	}

	.homepage .section1_mobile .title2 {
		background: var(
			--S-1,
			linear-gradient(46deg, #0092bd 0.24%, #00c67a 97.74%)
		);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: "Monument Extended";
		font-size: 2rem;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		text-transform: uppercase;
		padding-bottom: 30px;
		width: 50%;
		margin: 0;
		margin-left: 2rem;
	}

	.homepage .section1_mobile .btn {
		width: -moz-fit-content;
		width: fit-content;
		display: flex;
		padding: 10px 20px;
		align-items: center;
		gap: 10px;
		border-radius: 100px;
		border: 1px solid var(--Dark-primary, #152a31);
		background: #fff;
		box-shadow: -4px 4px 16px 0px rgba(0, 0, 0, 0.1);
		z-index: 2;
		position: relative;
		cursor: pointer;
		margin-left: 2rem;
	}

	.homepage .section1_mobile .mobile_hero_image {
		width: 100%;
		height: auto;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}

	.title_container {
		padding-top: 10rem;
		padding-bottom: 8rem;
		width: 100%;
		position: relative;
		background: rgb(0, 0, 0);
		background: rgb(255, 255, 255);
		background: rgb(255, 255, 255);
		background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 1) 75%,
			rgba(255, 255, 255, 0.05) 100%
		);
		z-index: 2;
	}

	.homepage .hero_container {
		position: relative;
		height: calc(100% - 40px);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.homepage .section_1_mobile_footer {
		border-radius: 50px 50px 0px 0px;
		background: var(--Dark-primary, #152a31);
		box-shadow: 0px -43px 86.1px 0px rgba(0, 0, 0, 0.25);
		height: max-content;
		z-index: 10;
		display: flex;
		margin: auto;
		margin-bottom: 2rem;
		width: 100%;
		justify-content: space-between;
		padding: 2rem 4rem;
		gap: 25px;
	}

	.homepage .section_1_mobile_footer .section_1_footer_div {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.5rem;
		justify-content: flex-end;
	}

	.homepage
		.section_1_mobile_footer
		.section_1_footer_div
		.section_1_footer_title {
		color: var(--White, #fff);
		font-family: Montserrat;
		font-size: 0.8rem;
		font-style: normal;
		font-weight: 400;
		line-height: 160%;
		white-space: nowrap;
	}

	.homepage
		.section_1_mobile_footer
		.section_1_footer_div
		.section_1_footer_count {
		color: var(--Green-2, #07dd92);
		font-family: Monument Extended;
		font-size: 0.9rem;
		font-style: normal;
		font-weight: 400;
		line-height: 160%;
	}

	.homepage .section_1_mobile_footer .section_1_footer_div svg {
		height: 40px;
		aspect-ratio: 1/1;
	}

	.homepage .section_see_us_action,
	.homepage .section_2,
	.homepage .section_3,
	.homepage .section_4 {
		padding: 2rem;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.homepage .section_2 .section_2_content {
		margin-top: 2rem;
		flex-direction: column-reverse;
	}

	.homepage .section_2 .title {
		font-size: 26px;
		padding-right: 0;
		width: 70%;
		text-align: center;
	}

	.homepage .section_2 .section_2_content .section_2_content_text {
		padding-left: 0;
	}

	.homepage
		.section_2
		.section_2_content
		.section_2_content_text
		.section_2_content_text_head {
		text-align: center;
	}

	.homepage .section_3 .case_studies {
		display: flex;
		flex-direction: column;
	}

	.homepage .section_see_us_action {
		padding: 0px 70px;
	}

	.homepage .section_see_us_action .title,
	.homepage .section_3 .title,
	.homepage .section_4 .title,
	.homepage .section_5 .title,
	.homepage .section_6 .title {
		font-size: 26px;
		padding-right: 0px;
	}

	.homepage .section_3 .desc,
	.homepage .section_4 .desc,
	.homepage .section_5 .desc {
		width: 30vw;
		text-align: center;
		margin-top: 0.8rem;
	}

	.homepage .section_5 .title {
		width: 100%;
		text-align: center;
	}

	.homepage .section_5 .title {
		width: 100%;
		text-align: center;
	}

	.homepage .section_5 .desc {
		margin: auto;
		width: 45vw;
	}

	.homepage .section_6 .title {
		margin: auto;
	}

	.homepage
		.section_6
		.fo.homepage
		.section_2
		.section_2_content
		.section_2_content_text
		.section_2_content_text_bodyunders_div {
		display: flex;
		padding: 0 8vw;
	}

	.homepage .section_6 .founders_div .founders_div_img {
		right: 10vw;
	}

	.homepage .section_6 .founders_div.left .founders_div_img {
		left: 10vw;
	}

	.homepage .section_6 .founders_div .founders_div_img {
		top: 7px;
	}

	.homepage .section_6 .founders_div .founders_div_img img {
		height: 80%;
	}

	.homepage
		.section_6
		.founders_div
		.founders_div_desc_div
		.founders_div_desc {
		font-size: 12px;
	}

	.homepage
		.section_6
		.founders_div
		.founders_div_desc_div
		.founders_div_title_div
		.founders_div_title {
		text-align: left;
	}

	.homepage .section_4 .what_we_offers {
		padding: 100px;
	}

	.homepage .section_5 {
		padding: 0px 70px;
	}

	.homepage .section_6 {
		padding: 50px 0;
	}

	.homepage .section_5 .associate_swiper_card {
		flex-direction: column;
	}

	.homepage .section_5 .associate_swiper_card .swiper_card_img img {
		width: 100%;
		max-height: 200px;
		min-height: auto;
	}

	.homepage .section_5 .associate_swiper_card .swiper_card_img {
		width: 100%;
		max-height: 200px;
		min-height: auto;
	}

	.homepage .section_5 .associate_swiper_card .swiper_card_div {
		gap: 25px;
		padding: 20px 30px;
	}

	.homepage .section_5 .associate_swiper_cards_nav {
		display: flex;
		gap: 30px;
		padding: 0;
		padding-top: 2rem;
		justify-content: center;
	}

	.homepage
		.section_2
		.section_2_content
		.section_2_content_text
		.section_2_content_text_body {
		width: 80vw;
		margin: auto;
	}

	.featured_article_swiper .swiper-slide {
		height: 500px;
	}
}

@media (max-width: 425px) {
	.homepage .section_see_us_action video {
		border-radius: 0;
	}

	.homepage
		.section_2
		.section_2_content
		.section_2_content_text
		.section_2_content_text_body {
		font-size: 15px;
		text-align: center;
	}

	.homepage .section_5 .associate_swiper_card .swiper_card_div {
		border-radius: 0 0 20px 20px;
	}

	.homepage .section1_mobile {
		height: 82vh;
	}

	.homepage .section1_mobile .title1 {
		font-size: 0.8rem;
		margin-left: 1rem;
	}

	.homepage .section1_mobile .title2 {
		font-size: 1.2rem;
		padding-bottom: 30px;
		width: 70%;
		margin-left: 1rem;
	}

	.homepage .section1_mobile .btn {
		padding: 6px 16px;
		margin-left: 1rem;
	}

	.title_container {
		padding-top: 6rem;
		padding-bottom: 4rem;
	}

	.homepage .section_1_mobile_footer {
		border-radius: 25px 25px 0px 0px;
		background: var(--Dark-primary, #152a31);
		box-shadow: 0px -43px 86.1px 0px rgba(0, 0, 0, 0.25);
		height: max-content;
		z-index: 10;
		display: flex;
		margin: auto;
		margin-bottom: 2rem;
		width: 100%;
		justify-content: space-between;
		padding: 2rem 1rem;
		gap: 0;
	}

	.homepage .section_1_mobile_footer .section_1_footer_div {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.25rem;
		justify-content: flex-end;
	}

	.homepage
		.section_1_mobile_footer
		.section_1_footer_div
		.section_1_footer_title {
		font-size: 0.7rem;
		margin-top: 5px;
	}

	.homepage
		.section_1_mobile_footer
		.section_1_footer_div
		.section_1_footer_count {
		font-size: 0.8rem;
	}

	.homepage .section_1_mobile_footer .section_1_footer_div svg {
		width: 30px;
		aspect-ratio: 1/1;
	}

	.homepage .section_see_us_action,
	.homepage .section_2,
	.homepage .section_3,
	.homepage .section_4 {
		padding: 2rem 1rem;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.homepage .section_2 .section_2_content {
		margin-top: 2rem;
		flex-direction: column-reverse;
	}

	.homepage .section_2 .title {
		font-size: 20px;
		width: 90%;
	}

	.homepage .section_2 .section_2_content .section_2_content_text {
		padding-left: 0;
	}

	.homepage
		.section_2
		.section_2_content
		.section_2_content_text
		.section_2_content_text_head {
		text-align: center;
	}

	.homepage .section_3 .desc,
	.homepage .section_4 .desc,
	.homepage .section_5 .desc {
		width: 90%;
		text-align: center;
		margin-top: 0.8rem;
	}

	.what_we_offer_homepage {
		flex-direction: column;
	}

	.homepage .section_4 {
		padding-left: 0;
		padding-right: 0;
	}

	.homepage .section_see_us_action {
		padding: 0px;
	}

	.homepage .section_5 {
		padding: 0px 1rem;
	}

	.homepage .section_6 .founders_div {
		flex-direction: column;
		padding: 0 10vw;
		border-radius: 40px 40px 0px 0px;
	}

	.homepage .section_6 .founders_div .founders_div_desc_div {
		padding-top: 40px;
		width: 100%;
	}

	.homepage .section_6 .founders_div .founders_div_img {
		position: static;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.homepage .section_6 .founders_div.left {
		flex-direction: column-reverse;
	}

	.homepage .section_6 .founders_div .founders_div_img img {
		height: 90%;
		margin-top: 1.8rem;
	}

	.homepage .section_7 {
		padding: 1rem;
	}

	.homepage .section_7 .title {
		padding-right: 0px;
		margin: auto;
	}

	.homepage .section_3 .title,
	.homepage .section_4 .title,
	.homepage .section_5 .title,
	.homepage .section_6 .title,
	.homepage .section_7 .title {
		font-size: 22px;
		padding-right: 0px;
	}

	.homepage .section_see_us_action .title {
		margin: 0px 1rem;
	}

	.homepage .section_5 .associate_swiper_card {
		flex-direction: column;
		width: 90vw;
	}

	.homepage .section_5 .swiper-slide-inactive {
		opacity: 0;
	}

	.homepage .section_4 .what_we_offers {
		margin-bottom: 4rem;
	}

	.homepage #why_choose_us_chart {
		scale: 0.7;
	}

	.homepage .section_2 .section_2_content .section_2_content_wheel {
		gap: 0;
	}

	.homepage
		.section_2
		.section_2_content
		.section_2_content_wheel
		.show_icon {
		margin-top: -70px;
	}

	.featured_article_swiper .swiper-slide {
		height: 330px;
	}
}
@media (max-width: 320px) {
	.homepage .section1_mobile .mobile_hero_image {
		top: 3rem;
	}
}
